// src/Album.js
import React, { useState } from 'react';
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Text,
  VStack,
  Image,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import './styles.css';
import AlbumModal from '../AlbumModal';

const Album = (props) => {
  const { albums, year, maxAlbumsPerRow } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [albumDetails, setAlbumDetails] = useState({ cover: '', tracks: [] });

  const fetchAlbumDetails = async (year, folder) => {
    const response = await fetch(`/api/album/${year}/${folder}`);
    const data = await response.json();
    setAlbumDetails(data);
  };

  const handleAlbumClick = (album) => {
    setSelectedAlbum({ ...album, year });  // Include the year here
    console.log(year);
    fetchAlbumDetails(year, album.folder);
    onOpen();
  };

  return (
    <Box mb={10}>
      <Box display="flex" justifyContent="center" alignItems="center" mb={5}>
        <Heading as="h2" size="lg" textAlign="center" mb={5} color="teal.400">
          /v/ the Musical {year}
        </Heading>
        <IconButton
          as="a"
          href={props.download}
          aria-label={`Download ${year}`}
          icon={<DownloadIcon />}
          size="sm"
          ml={3}
          colorScheme="teal"
          target="_blank" 
          rel="noopener noreferrer"
        />
      </Box>
      {albums.reduce((rows, album, index) => {
        if (index % maxAlbumsPerRow === 0) rows.push([]);
        rows[rows.length - 1].push(album);
        return rows;
      }, []).map((row, rowIndex) => (
        <Box key={rowIndex} display="flex" justifyContent="center" mb={6}>
          <Grid
            templateColumns={`repeat(${row.length}, 1fr)`}
            gap={6}
            justifyContent="center"
            maxWidth={`${maxAlbumsPerRow * 240}px`}
          >
            {row.map((album) => (
              <GridItem key={album.folder} onClick={() => handleAlbumClick(album)}>
                <VStack className="album-container">
                  <Image className="album-image" src={album.cover} alt={`${album.name} cover`} />
                  <Text className="album-name">{album.name}</Text>
                </VStack>
              </GridItem>
            ))}
          </Grid>
        </Box>
      ))}

      <AlbumModal isOpen={isOpen} onClose={onClose} album={selectedAlbum} albumDetails={albumDetails} />
    </Box>
  );
};

export default Album;
